:root {
    --primary: #003366;
    --secondary: #66CC66;
    --neutral-light: #F0F0F0;
    --white: #FFFFFF;
    --font-main: 'Poppins', sans-serif;
  }
  
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: var(--font-main);
    color: var(--primary);
    background-color: var(--white);
    line-height: 1.6;
  }
  
  .container {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  /* Header & Navigation */
  header {
    background-color: var(--white);
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
  }
  
  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
  }
  
  .logo {
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--primary);
    text-decoration: none;
  }
  
  .logo span {
    color: var(--secondary);
  }
  
  nav ul {
    display: flex;
    list-style: none;
  }
  
  nav ul li {
    margin-left: 2rem;
  }
  
  nav ul li a {
    text-decoration: none;
    color: var(--primary);
    font-weight: 500;
    transition: color 0.3s ease;
  }
  
  nav ul li a:hover {
    color: var(--secondary);
  }
  
  /* Main Content */
  main {
    padding-top: 80px;
  }
  
  .page-header {
    background-color: var(--neutral-light);
    padding: 3rem 0;
    text-align: center;
  }
  
  .page-title {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  /* Home Page */
  .filter-section {
    padding: 2rem 0;
    background-color: var(--white);
  }
  
  .filter-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .filter-group {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .filter-label {
    margin-right: 1rem;
    font-weight: 500;
  }
  
  .filter-select, .search-input {
    padding: 0.5rem;
    border: 1px solid var(--primary);
    border-radius: 4px;
    background-color: var(--white);
    color: var(--primary);
  }
  
  .search-input {
    width: 200px;
  }
  
  .templates-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 2rem;
    padding: 2rem 0;
  }
  
  .template-card {
    background-color: var(--white);
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    transition: transform 0.3s ease;
  }
  
  .template-card:hover {
    transform: translateY(-5px);
  }
  
  .template-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .template-info {
    padding: 1rem;
  }
  
  .template-title {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
  
  .template-description {
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 1rem;
  }
  
  .template-link {
    display: inline-block;
    padding: 0.5rem 1rem;
    background-color: var(--secondary);
    color: var(--white);
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .template-link:hover {
    background-color: #55BB55;
  }
  
  .load-more {
    display: block;
    width: 200px;
    margin: 2rem auto;
    padding: 1rem;
    background-color: var(--primary);
    color: var(--white);
    text-align: center;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .load-more:hover {
    background-color: #002255;
  }
  
  /* Contact Page */
  .contact-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    padding: 2rem 0;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid var(--primary);
    border-radius: 4px;
  }
  
  .form-group textarea {
    height: 150px;
  }
  
  button[type="submit"] {
    padding: 0.5rem 1rem;
    background-color: var(--secondary);
    color: var(--white);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button[type="submit"]:hover {
    background-color: #55BB55;
  }
  
  .contact-info {
    background-color: var(--neutral-light);
    padding: 2rem;
    border-radius: 8px;
  }
  
  .info-item {
    margin-bottom: 1.5rem;
  }
  
  .info-item h3 {
    margin-bottom: 0.5rem;
  }
  
  .social-links {
    display: flex;
    gap: 1rem;
  }
  
  .social-links a {
    color: var(--primary);
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .social-links a:hover {
    color: var(--secondary);
  }
  
  /* About Us Page */
  .about-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    align-items: center;
    padding: 2rem 0;
  }
  
  .about-image img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .team-section {
    background-color: var(--neutral-light);
    padding: 3rem 0;
  }
  
  .team-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    margin-top: 2rem;
  }
  
  .team-member {
    text-align: center;
  }
  
  .team-member img {
    width: 100%;
    max-width: 250px;
    border-radius: 50%;
    margin-bottom: 1rem;
  }
  
  .team-member-info h3 {
    margin-bottom: 0.5rem;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .contact-container,
    .about-content {
      grid-template-columns: 1fr;
    }
  
    .nav-links {
      display: none;
    }
    
    .menu-toggle {
      display: block;
    }
    
    .nav-links.show {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      background-color: var(--white);
      box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    }
    
    .nav-links.show li {
      margin: 0;
      text-align: center;
    }
    
    .nav-links.show a {
      display: block;
      padding: 1rem;
    }
  }